import React from "react"
import { graphql } from "gatsby"
// import { mdLocationOn } from "react-icons/md"
import { MdLocationOn, MdMail, MdPhone } from "react-icons/md"

import ContactForm from "../components/ContactForm"
import CommitteeGrid from "../components/CommitteeGrid"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import { PageTitle, SubTitle } from "../components/SiteComponents"
// Used to format the contact  icons uniformly
const IconContainer = ({ children }) => (
  <div className="text-4xl text-nofnecRed flex items-center">{children}</div>
)

const Contact = ({ data }) => {
  const {
    primaryContactName: name,
    primaryContactTitle: jobTitle,
  } = data.planners
  const {
    address1: addressLine1,
    address2: addressLine2,
    city,
    country,
    provinceState: province,
    postalCode,
  } = data.planners.contactInfo.mailingAddress
  const { email, phone, fax } = data.planners.contactInfo
  const url = data.planners.orgInfo.url
  const { orgName } = data.planners.orgInfo

  return (
    <Layout>
      <SEO title="Contact Us" />
      <div className="contact__container flex flex-col items-center bg-gray-300 text-center py-10 ">
        <PageTitle className="mb-8  text-nofnecGreen mt-20 md:mt-10">
          Contact Us
        </PageTitle>
        {/* center box */}
        <div className="bg-white md:w-10/12 shadow-md">
          <div className="grid p-8 col-gap-5 justify-items-center">
            {/* outer contact info box */}
            {null && (
              <div className="flex text-left text-xl px-5 justify-center items-start">
                {/* inner contact info box */}
                <div className="w-9/12">
                  <h3>
                    For information, comments and suggestions, please contact:
                  </h3>
                  {/* contact person container */}
                  <div className="grid grid-cols-contact-details col-gap-5 row-gap-5 mt-3 leading-tight">
                    <IconContainer>
                      <MdLocationOn />
                    </IconContainer>

                    <div>
                      <p className="font-semibold">{name}</p>
                      <p>{jobTitle}</p>
                      <p>{orgName}</p>
                      <p>{addressLine1}</p>
                      <p>{addressLine2}</p>
                      <p>
                        {province}, {postalCode}
                      </p>
                    </div>
                    <IconContainer>
                      <MdMail className="text-2xl text-nofnecRed" />
                    </IconContainer>

                    <a
                      href={`mailto:${email}?subject=NOFNEC`}
                      className="flex items-center hoverLinks"
                    >
                      {email}
                    </a>

                    <IconContainer>
                      <MdPhone />
                    </IconContainer>
                    <a
                      href={`tel:+1{phone}`}
                      className="flex items-center hoverLinks"
                    >
                      {phone}
                    </a>
                  </div>
                  <div className="mt-6 hoverLinks">
                    <a href={url} target="_blank">
                      {url}
                    </a>
                  </div>
                </div>
              </div>
            )}

            {/* Contact Form Container */}
            <div className="flex bg-gray-300 justify-center items-center text-left mt-10 p-10 max-w-2xl shadow-lg w-full">
              <ContactForm className="w-full max-w-2xl" />
            </div>
          </div>
          <div className="p-8">
            <SubTitle className="text-3xl uppercase tracking-wide font-bold mb-8">
              NOFNEC Planning Committee
            </SubTitle>
            <div className="flex flex-wrap content-center justify-center row-gap-10 col-gap-8">
              <CommitteeGrid />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery {
    planners: sanityPlanners {
      orgInfo {
        orgName
        url
      }
      contactInfo {
        email
        phone
        fax
        mailingAddress {
          address1
          address2
          city
          country
          postalCode
          provinceState
        }
      }
      primaryContactName
      primaryContactTitle
    }
  }
`

export default Contact
