import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ContactText = ({ children, className }) => (
  <div className={`text-xl ${className}`}>{children}</div>
)

const CommitteeGrid = () => {
  const { committee, defaultProfilePhoto } = useStaticQuery(graphql`
    query CommitteeGridQuery {
      committee: allSanityCommitteeMember {
        edges {
          node {
            id
            active
            name
            contactInfo {
              email
              phone
              fax
              mailingAddress {
                address1
                address2
                city
                country
                postalCode
                provinceState
              }
            }
            slug {
              current
            }
            affiliation {
              title
              url
            }
            profile {
              profilePhoto {
                asset {
                  fixed(width: 200) {
                    ...GatsbySanityImageFixed
                  }
                }
              }
            }
          }
        }
      }
      defaultProfilePhoto: file(relativePath: { eq: "Profile.png" }) {
        id
        childImageSharp {
          fixed(width: 125, fit: CONTAIN, cropFocus: NORTH) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const defaultProfileImage = defaultProfilePhoto.childImageSharp.fixed

  const committeeArray = committee.edges

  // Sort committee array

  committeeArray.sort((a, b) => {
    let nameA = a.node.name.trim().toUpperCase()
    let nameB = b.node.name.trim().toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  // .filter() does not change the original array
  const filteredArray = committeeArray.filter(item => item.node.active)

  // End sort committee array
  return (
    <>
      {filteredArray.map(({ node: member }) => {
        // this is used for logic in multiple locations
        const orgLogo = member?.profile?.profilePhoto?.asset?.fixed || null
        const profileImage = orgLogo || defaultProfileImage

        const { contactInfo } = member

        return (
          <div className="grid shadow-md p-3 mb-5 ml-5" key={member.id}>
            <div className=" w-56 h-56 grid-self-center flex items-center justify-center">
              {orgLogo ? (
                <a
                  href={member?.affiliation?.url || "/#"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transform hover:scale-110 transition ease-in-out duration-100 "
                >
                  <Img fixed={profileImage} />
                </a>
              ) : (
                <Img fixed={profileImage} />
              )}
            </div>
            <div>
              <h3 className="mt-2">{member.name}</h3>

              <div className="">
                {member.affiliation && (
                  <ContactText className="font-semi-bold italic">
                    {member.affiliation.title}
                  </ContactText>
                )}
                {contactInfo && (
                  <div>
                    {contactInfo.phone && (
                      <ContactText>{contactInfo.phone}</ContactText>
                    )}
                    {contactInfo.email && (
                      <ContactText>
                        <a
                          href={`mailto:${contactInfo.email}?subject=NOFNEC`}
                          className="hoverLinks text-nofnecGreen hover:text-nofnecRed"
                        >
                          {contactInfo.email}
                        </a>
                      </ContactText>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default CommitteeGrid
