import React from "react"

const FormItem = ({ children }) => (
  <div className="grid row-gap-1">{children}</div>
)

const FormInput = ({
  children,
  id,
  name,
  type,
  placeholder = "",
  required = null,
  extraClasses = "",
}) => (
  <input
    className={`px-1 pt-1 pb-0 border-none rounded-sm h-8 ${extraClasses}`}
    id={id}
    name={name}
    type={type}
    placeholder={placeholder}
    required={required}
  >
    {children}
  </input>
)

const ContactForm = ({ className = "" }) => {
  return (
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/success"
      className={`grid row-gap-3  ${className}`}
    >
      <input type="hidden" name="form-name" value="contact" />
      {/* HoneyPot */}
      <div className="hidden h-0">
        <label>
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
      </div>
      {/* Honeypot End */}
      <h2 className="font-bold text-center text-4xl">Send Us a Message</h2>
      <FormItem className="contact__item">
        <label htmlFor="form__name">
          Name:<sup className="form__required">*</sup>
        </label>
        <FormInput
          id="form__name"
          name="name"
          type="text"
          placeholder="Enter Your Name"
          required
        />
      </FormItem>
      <FormItem className="contact__item">
        <label htmlFor="form__tel">Phone Number:</label>
        <FormInput
          name="phone"
          id="form__tel"
          type="tel"
          placeholder="555-555-5555"
          required
        />
      </FormItem>
      <FormItem className="contact__item">
        <label htmlFor="form__email">
          Email:<sup className="form__required">*</sup>
        </label>
        <FormInput
          name="email"
          id="form__email"
          type="email"
          placeholder="your@email.ca"
        />
      </FormItem>
      <FormItem className="contact__item">
        <label htmlFor="form__details">
          Details:<sup className="form__required">*</sup>
        </label>
        <textarea
          className="px-1 pt-1 pb-0"
          name="message"
          id="form__details"
          required
        ></textarea>
      </FormItem>
      <button
        type="submit"
        className="text-white font-bold uppercase text-2xl bg-blue-500 h-10 flex justify-center items-center hover:text-blue-500 hover:bg-white hover:border hover:border-blue-500 mt-5"
      >
        Submit
      </button>
    </form>
  )
}

export default ContactForm
